export const environment = {
  'production': false,
  'stage': 'dev',
  'deployment': 'au',
  'mapbox_access_token': 'pk.eyJ1IjoicGV0YWJlbmNhbmEiLCJhIjoiY2s2MjF1cnZmMDlxdzNscWc5MGVoMTRkeCJ9.PGcoQqU6lBrcLfBmvTrWrQ',
  'data_server': 'https://fakeapi.com/',
  'app': 'https://fakeapp.com/',
  'supportedDecks': [
    {
      'path': 'flood',
      'loadChildren': './flood/flood.module#FloodModule'
    }
  ],
  'supportedCards': {
    'flood': [
      {
        'path': 'location',
        'loadChildren': '../../cards/location/location.module#LocationModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'depth',
        'loadChildren': '../../cards/depth/depth.module#DepthModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'photo',
        'loadChildren': '../../cards/photo/photo.module#PhotoModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'description',
        'loadChildren': '../../cards/description/description.module#DescriptionModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'review',
        'loadChildren': '../../cards/review/review.module#ReviewModule',
        'data': {
          'preload': true
        }
      },
      {
        'path': 'thank',
        'loadChildren': '../../cards/thank/thank.module#ThankModule',
        'data': {
          'preload': true
        }
      }
    ]
  }
};
